import { TextHandlers, textHandlers } from "./textHelper";
import { useLocalStorage } from "@uidotdev/usehooks";

interface Translator {
  enabled: boolean;
  dictionary: boolean;
  service: string;
  sourceLang: string;
  targetLang: string;
  autoCopy: boolean;
}

interface Advanced {
  SendAnnomousUsageData: boolean;
  DarkTheme: boolean;
  LocalModel: string;
}

export interface KiwiSettings {
  translator: Translator;
  autoConvert: TextHandlers;
  advanced: Advanced;
}

export const defaultSettings: KiwiSettings = {
  translator: {
    enabled: true,
    dictionary: true,
    service: "xiaoniu",
    sourceLang: "en",
    targetLang: "zh",
    autoCopy: false,
  },
  autoConvert: textHandlers,
  advanced: {
    SendAnnomousUsageData: true,
    DarkTheme: false,
    LocalModel: "opus-mt-en-zh",
  },
};

export const useSettings = () => {
  const [settings, setSettings] = useLocalStorage<KiwiSettings>(
    "settings",
    defaultSettings
  );
  return [settings, setSettings] as const;
};

export const getSettings = (): KiwiSettings => {
  const settings = localStorage.getItem("settings");
  if (settings) {
    return JSON.parse(settings);
  } else {
    return defaultSettings;
  }
};

export const resetSettings = () => {
  localStorage.removeItem("settings");
};
