// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from "react";
import {
  Nav,
  Avatar,
  Steps,
  ButtonGroup,
  Notification,
} from "@douyinfe/semi-ui";
import { IconHelpCircle } from "@douyinfe/semi-icons";
import { useDocumentTitle } from "@uidotdev/usehooks";
import styles from "./style/index.module.scss";
import { Link } from "react-router-dom";
import ShowFiles from "./showFiles";
import { FileUpload } from "./fileUpload";
import ImportFileModal from "./importFileModal";
import { handleFileChange } from "./utils/importFile";

const Layout = () => {
  useDocumentTitle("文件 - Kiwi PDF Reader");
  const [count, setCount] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const dragCounter = useRef(0);

  const incrementCount = () => {
    setCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    const handleWindowDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current++;
      if (e.dataTransfer.types.includes("Files")) {
        setIsDragging(true);
      }
    };

    const handleWindowDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current--;
      if (dragCounter.current === 0) {
        setIsDragging(false);
      }
    };

    const handleWindowDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleWindowDrop = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      dragCounter.current = 0;

      const files = Array.from(e.dataTransfer.files).filter(
        (file) => file.type === "application/pdf"
      );

      if (files.length === 0) {
        Notification.warning({
          title: "仅支持上传 PDF 文件",
        });
        return;
      }

      await handleFileChange({ target: { files } }, incrementCount, false);
    };

    window.addEventListener("dragenter", handleWindowDragEnter);
    window.addEventListener("dragleave", handleWindowDragLeave);
    window.addEventListener("dragover", handleWindowDragOver);
    window.addEventListener("drop", handleWindowDrop);

    return () => {
      window.removeEventListener("dragenter", handleWindowDragEnter);
      window.removeEventListener("dragleave", handleWindowDragLeave);
      window.removeEventListener("dragover", handleWindowDragOver);
      window.removeEventListener("drop", handleWindowDrop);
    };
  }, []);

  return (
    <div
      className={`${styles.rootSidenavTab} ${isDragging ? styles.dragging : ""}`}
    >
      <Nav
        mode="horizontal"
        header={{
          logo: (
            <div className={styles.navigationHeaderLogo}>
              <Link
                to="https://kiwi.moe"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                🥝
              </Link>
            </div>
          ),
          text: "Kiwi PDF",
        }}
        footer={
          <div className={styles.dIV}>
            <IconHelpCircle
              size="large"
              className={styles.semiIconsHelpCircle}
            />
            <Avatar
              size="small"
              src="/assets/neko.jpg"
              color="blue"
              className={styles.avatar}
            >
              我
            </Avatar>
          </div>
        }
        className={styles.nav}
      >
        <Link
          to="https://github.com/TennousuAthena/kiwiPDF?tab=readme-ov-file#kiwi-pdf-reader"
          style={{ textDecoration: "none" }}
          target="_blank"
        >
          <Nav.Item itemKey="About" text="About" />
        </Link>
      </Nav>
      <div className={styles.main}>
        <div className={styles.content}>
          <Steps type="nav" current={1} size="small">
            <Steps.Step title="/" />
            <Steps.Step title="Files" />
          </Steps>
          <div className={styles.content}>
            <ButtonGroup style={{ alignContent: "right" }}>
              <FileUpload onUpdate={incrementCount} />
              <ImportFileModal onUpdate={incrementCount} />
            </ButtonGroup>
            <div className={styles.yourContent}>
              <ShowFiles refresh={count} />
            </div>
          </div>
        </div>
      </div>
      {isDragging && (
        <div className={styles.dropZone}>
          <div className={styles.dropZoneContent}>拖放 PDF 文件到这里</div>
        </div>
      )}
    </div>
  );
};

export default Layout;
